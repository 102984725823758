.form {
    background: transparent;
    border: 1px solid var(--dark-color-half-transparent);
    padding: 16px;
    border-radius: 16px;
}
input {
    background: var(--white-color);
    color: var(--dark-color);
    border: 1px solid var(--dark-color-half-transparent);
    width: calc(100% - 26px);
    padding: 5px 7px;
    margin: 6px;
    border-radius: 4px;
    font-size: 1em;
}
.googleLoginButton {
    /* background: linear-gradient(45deg, rgba(255,0,0,1) 0%, rgba(255,0,0,1) 4%, rgba(241,255,0,1) 4%, rgba(241,255,0,1) 8%, rgba(31,247,31,1) 8%, rgba(31,247,31,1) 12%, rgba(0,168,255,1) 12%, rgba(0,168,255,1) 16%, rgba(222,222,222,1) 16%); */
    background: linear-gradient(45deg, rgba(255,195,195,1) 0%, rgba(186,231,255,1) 100%);
    color: var(--dark);
    border: none;
    display: block;
    margin: 6px;
    padding: 12px 12px 12px 12px;
    width: calc(100% - 12px);
    border-radius: 12px;
    font-size: 1em;
    opacity: 0.7;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}