/* Alerts styles */
.alert-body {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 15px;
    z-index: 999;
    padding: 15px 15px 0 0;
    max-width: calc(100% - 30px);
}
.alert-hidden {
    position: absolute;
    top: -20px;
    transition: top 1s;
}
.alert {
    position: fixed;
    bottom: 0px; left: 50%;
    width: calc(100% - 102px);
    backdrop-filter: blur(5px);
    max-width: 840px;
    padding: 10px 10px 10px 42px;
    border-radius: 15px;
    opacity: 0;
    animation: alert 7s linear;
    -webkit-animation: alert 7s linear;
    -moz-animation: alert 7s linear;
    -o-animation: alert 7s linear;
    transform: translateX(-50%);
    z-index: 999;
}
.alert-warning {
    background: 11px / 20px no-repeat url(../../Media/alert-warning.png);
    background-color: #f38686df;
    border: 2px solid #cb7474;
    --alert-color: #f38686;
    
}
.alert-info {
    background: 11px / 20px no-repeat url(../../Media/alert-info.png);
    background-color: #869ff3df;
    border: 2px solid #7488cb;
    --alert-color: #869ff3;
}
.alert-success {
    background: 11px / 20px no-repeat url(../../Media/alert-success.png);
    background-color: #86f391df;
    border: 2px solid #74cb7a;
    --alert-color: #86f391;
}

@keyframes alert {
    0% {
        opacity: 0;
    }
    2% {
        opacity: 1;
        bottom: 20px;
    }
    97% {
        opacity: 1;
        bottom: 20px;
    }
    99% {
        opacity: 0;
        display: block;
    }
    100% {
        display: none;
    }
}