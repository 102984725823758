.Button {
  font-family: Title, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: inline-block;
  padding: 10px 24px;
  margin: 6px;
  border: none;
  background: var(--gray-2-ht);
  color: var(--dark);
  border-radius: 30px;
  font-size: 18px;
}
.Button:hover {
  background: var(--gray-2);
  cursor: pointer;
}
.ButtonFocus {
    background: var(--accent-ht);
    border: none;
    color: var(--dark);
}
.ButtonFocus:hover {
    background: var(--accent);
}

@media (max-width: 896px) {
  .Button {
    display: block;
    width: calc(100% - 12px);
  }
}